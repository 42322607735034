const EditModal = ({
    children,
    title,
    closeModal,
    submitHandler,
    isLoading,
}: any) => {
    return (
        <>
            <div
                id="backdrop"
                onClick={closeModal}
                tabIndex={-1}
                className="fixed w-screen h-screen inset-0 z-40 bg-black opacity-25"
            ></div>
            <form
                encType="multipart/form-data"
                onSubmit={submitHandler}
                className="flex flex-col md:w-2/3 w-11/12 left-1/2 top-4 max-w-xl -tanslate-y-full rounded-xl shadow-lg -translate-x-1/2 bg-white dark:bg-neutral-900 z-50 fixed anim-2"
            >
                <div className="px-8 py-4 flex justify-between items-center">
                    <h2 className="font-semibold">{title}</h2>
                    <button onClick={closeModal} className="font-bold">
                        &times;
                    </button>
                </div>
                <div className="px-8 py-4 flex flex-col gap-4">{children}</div>
                <div className="flex justify-end gap-2 rounded-b-xl bg-neutral-100 dark:bg-neutral-800 px-4 py-2">
                    <button
                        type="button"
                        onClick={closeModal}
                        className="py-2 px-4 bg-neutral-200 dark:bg-neutral-600 rounded"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="py-2 px-4 bg-success text-white rounded"
                    >
                        {isLoading ? (
                            <span className="loading loading-ring loading-sm"></span>
                        ) : (
                            "Submit"
                        )}
                    </button>
                </div>
            </form>
        </>
    );
};

export default EditModal;
