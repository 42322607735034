import { getWithCaching } from "../utilities/caching";
import api from "../utilities/fetching";

export const logout = async (token: string) => {
  return await api.delete("logout");
};

export const changePassword = async (payload: any) => {
  return await api.put("user/password", payload);
};

export const getTotalRevenue = async () => {
  return getWithCaching("total_revenue", () => api.get("revenue"));
};

export const getTotalTime = async (days = 7) => {
  return getWithCaching("total_time", () =>
    api.get(`stopwatch/stats?days=${days}`),
  );
};

export const getScans = async (days = 7) => {
  const finishDate = new Date(); // Today's date
  const startDate = new Date(); // Clone today's date
  startDate.setDate(startDate.getDate() - days); // Subtract 'days' from today's date

  // Helper function to format date as 'YYYY-MM-DD'
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const start = formatDate(startDate);
  const finish = formatDate(finishDate);

  // Make the API call with the formatted dates
  return await api.get(`qr/scans?start=${start}&finish=${finish}`);
};

export const getTableTime = async (days = 7) => {
  return getWithCaching("table_time", () =>
    api.get(`tables/stats?days=${days}`),
  );
};

export const getDailyTotalTime = async (days = 30) => {
  return getWithCaching("total_daily_time", () =>
    api.get(`stopwatch/stats/total?days=${days}`),
  );
};

export const getUsers = async () => {
  return await api.get("users");
};

export const toggleActive = async (id: number, active: boolean) => {
  return active
    ? await api.patch(`users/${id}/enable`)
    : await api.patch(`users/${id}/disable`);
};

export const registerUser = async (payload: any) => {
  return await api.post("register", payload);
};
