const Links = [
    { id: 1, route: "/", name: "Dashboard", icon: "home", access: true },
    {
        id: 2,
        route: "/products",
        name: "Products",
        icon: "menu",
        access: false,
        drawer: true,
        children: [
            {
                id: 201,
                route: "/products",
                name: "Products",
                access: true,
            },
            {
                id: 202,
                route: "/products/posters",
                name: "Posters",
                access: false,
            },

        ],

    },
    {
        id: 7,
        route: "/orders/tables",
        name: "Orders",
        icon: "orders",
        access: true,
        drawer: true,
        children: [
            {
                id: 701,
                route: "/orders/tables",
                name: "Tables",
                access: true,
            },
            {
                id: 702,
                route: "/orders/active",
                name: "Active Orders",
                access: true,
            },
            {
                id: 703,
                route: "/orders/history",
                name: "History",
                access: false,
            },
        ],
    },
    {
        id: 8,
        route: "/management",
        name: "Management",
        icon: "employees",
        access: false,
        drawer: true,
        children: [
            {
                id: 801,
                route: "/management/accounts",
                name: "Manage Accounts",
                access: false,
            },
            {
                id: 802,
                route: "/management/accounts/register",
                name: "Add Account",
                access: false,
            },
        ],
    },
    {
        id: 3,
        route: "/members",
        name: "Memberships",
        icon: "users",
        access: true,
        drawer: true,
        children: [
            { id: 301, route: "/members", name: "Members", access: true },
            { id: 302, route: "/members/games", name: "Games", access: true },
        ],
    },
    { id: 5, route: "/billiard", name: "Stopwatch", icon: "pool", access: true },
    { id: 6, route: "/history", name: "History", icon: "history", access: false },
];

export default Links;
