import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
    getDailyTotalTime,
    getScans,
    getTableTime,
    getTotalRevenue,
    getTotalTime,
} from "../../services/User";
import Cards from "../../UI/Cards";
import TrendCards from "../../UI/TrendCards";
import BarChart from "../../UI/BarChart";
import Select from "../../UI/Select";
import AreaChart from "../../UI/LineChart";
import ScansChart from "../../UI/ScansChart";

const AdminHome = () => {
    const [revenue, setRevenue]: any = useState({});
    const [stopwatch, setStopwatch]: any = useState({});
    const [tableTime, setTableTime]: any = useState([]);
    const [dailyTotalStats, setDailyTotalStats]: any = useState([]);
    const [isLoading, setIsLoading]: any = useState(false);
    const [scans, setScans]: any = useState([]);

    const getRevenue = async () => {
        try {
            setIsLoading(true);
            const response = await getTotalRevenue();
            const res = await getTotalTime();
            const resp = await getTableTime(30);
            const dailyStatsResponse = await getDailyTotalTime(); // Fetch daily total stats
            const scansResponse = await getScans(7);

            setRevenue(response.data);
            setStopwatch(res.data);
            setTableTime(resp.data);
            setDailyTotalStats(dailyStatsResponse.data);
            setScans(scansResponse);
        } catch (err: any) {
            console.log(err.response);
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getRevenue();
    }, []);
    return (
        <div className="w-full flex flex-col gap-1">
            <div className="m-4 flex gap-4 flex-col md:flex-row">
                <TrendCards
                    value={stopwatch.thisMonth}
                    text="hours played"
                    icon={"clockIcon"}
                    diff={stopwatch.difference}
                    desc={stopwatch.differenceFormatted}
                    altText="last 7 days"
                />
                <Cards
                    value={revenue.membershipRevenue}
                    text="ден."
                    icon={"cardIcon"}
                    desc="Memberships Total"
                />
                <Cards
                    value={revenue.activeMemberships}
                    text="members"
                    icon={"usersIcon"}
                    desc="Active Memberships"
                />
            </div>
            <div className="m-4 flex gap-8 md:gap-4 flex-col md:flex-row flex-shrink flex-grow-0">
                {tableTime.length > 0 && <BarChart data={tableTime} />}
                {scans.length > 0 && <ScansChart data={scans} />}
            </div>
            <div className="m-4 flex gap-8 md:gap-4 flex-col md:flex-row flex-shrink flex-grow-0">
                {dailyTotalStats.length > 0 && (
                    <AreaChart data={dailyTotalStats} />
                )}{" "}
            </div>
        </div>
    );
};

export default AdminHome;
