import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

const ScansChart = ({ data }) => {
    ChartJS.register(...registerables);

    const chartData = {
        labels: data.map((item) => item.scan_date), // Map scan_date for labels
        datasets: [
            {
                label: "Total Scans",
                data: data.map((item) => parseInt(item.total_scans) || 0), // Map total_scans for data
                fill: true,
                backgroundColor: "rgba(75, 192, 192, 0.2)", // Light teal for the background
                borderColor: "rgba(75, 192, 192, 1)", // Dark teal for the border
                tension: 0.4, // Adjust the line smoothness
            },
        ],
    };

    return (
        <div className="chart-container min-w-0 p-4 w-full bg-white dark:bg-neutral-800 rounded-xl shadow-lg">
            <div className="px-2">
                <h2 style={{ textAlign: "left" }}>Scans Over Time</h2>
                <p
                    style={{ textAlign: "left" }}
                    className="text-neutral-400 dark:text-neutral-500 text-xs font-semibold py-2"
                >
                    Last 7 days
                </p>
            </div>

            <Line
                data={chartData}
                options={{
                    scales: {
                        x: {
                            type: "category",
                            title: {
                                display: true,
                                text: "Date",
                            },
                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: "Total Scans",
                            },
                        },
                    },
                    elements: {
                        line: {
                            tension: 0.4, // Adjust the tension of the line
                            borderWidth: 2,
                            borderCapStyle: "round",
                            borderJoinStyle: "round",
                        },
                    },
                    plugins: {
                        title: {
                            display: false,
                        },
                        legend: {
                            display: true, // Display the legend
                            position: "top",
                        },
                    },
                }}
            />
        </div>
    );
};

export default ScansChart;

