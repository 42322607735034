import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EditIcon, GarbageIcon } from "../../assets/icons";
import AddModal from "../../components/modals/AddModal";
import EditModal from "../../components/modals/EditModal";

import {
    Poster,
    addPoster,
    editPoster,
    getPosters,
    removePoster,
} from "../../services/Products";
import Select from "../../UI/Select";

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let min = 0; min < 60; min += 30) {
            const time = `${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
            times.push(time);
        }
    }
    return times;
};


const Posters = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [posters, setPosters] = useState<Poster[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [addModal, setAddModal] = useState(false);

    const [editId, setEditId] = useState(0);
    const [editName, setEditName] = useState("");
    const [editOrder, setEditOrder] = useState("");
    const [editRunFrom, setEditRunFrom] = useState("0");
    const [editRunTo, setEditRunTo] = useState("0");
    const [addRunFrom, setAddRunFrom] = useState("0");
    const [addRunTo, setAddRunTo] = useState("0");
    const [image, setImage] = useState("");
    const [editActive, setEditActive] = useState(true);

    const timeOptions = generateTimeOptions();

    async function fetchPosters() {
        try {
            const response = await getPosters() as { data: Poster[] }

            setPosters(response.data);

        } catch (error) {
            toast.error(error?.message);
        }
    }
    useEffect(() => {
        fetchPosters();
    }, []);

    const confirmDelete = async (id: number) => {
        const confirmed = window.confirm("Are you sure?");

        if (confirmed) {
            try {
                const response = await removePoster(id);
                setPosters((prev: Poster[]) => prev.filter((e: Poster) => e.id !== id));
                toast.info("Poster successfully removed!");
            } catch (error) {
                toast.error(error?.message);
            }
        }
    };

    const editModalHandler = async (id: number) => {
        setShowModal(true);
        const product = posters.filter((e: Poster) => e.id === id)
        setEditName(product[0].name);
        setEditActive(product[0].active);
        setEditOrder(product[0].priority);
        setImage(product[0].image_url);
        if (product[0].run_from)
            setEditRunFrom(product[0].run_from.substr(0, 5));
        if (product[0].run_to)
            setEditRunTo(product[0].run_to.substr(0, 5));
        setEditId(id);
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        const payload: any = new FormData();
        e.target.editImage.files[0] &&
            payload.append("image", e.target.editImage.files[0]);
        payload.append("name", editName);
        payload.append("active", editActive); // Convert boolean to string ("1" or "0")
        console.log(editRunFrom)
        if ((editRunFrom !== "0" || editRunFrom) && (editRunTo !== "0" || editRunTo)) {
            payload.append("run_from", editRunFrom + ":00");
            payload.append("run_to", editRunTo + ":00");
        }
        payload.append("priority", editOrder);
        try {
            setIsLoading(true);
            await editPoster(editId, payload);

            fetchPosters();
            toast.success("Product edited!");
            setShowModal(false);
        } catch (err) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const addSubmitHandler = async (e: any) => {
        e.preventDefault();
        const payload: any = new FormData();

        e.target.image.files[0] && payload.append("image", e.target.image.files[0]);
        payload.append("name", e.target.editName.value);
        payload.append("active", e.target.editActive.checked ? 1 : 0);
        payload.append("priority", e.target.editOrder.value);
        if (addRunFrom !== "0" && addRunTo !== "0") {
            payload.append("run_from", addRunFrom + ":00");
            payload.append("run_to", addRunTo + ":00");
        }

        try {
            setIsLoading(true);
            await addPoster(payload);

            toast.success("Product added!");
            setAddModal(false);
            fetchPosters();
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="text-sm breadcrumbs px-6">
                <ul>
                    <li>
                        <a>Posters</a>
                    </li>
                </ul>
            </div>

            <button
                onClick={() => setAddModal(true)}
                className="btn btn-success mx-4 my-4 shadow text-white"
            >
                Add new
            </button>

            <ul className="bg-white dark:bg-neutral-800 mx-4 rounded-lg  mb-24">
                {posters.map((e: Poster) => {
                    return (
                        <li
                            className="flex justify-between items-center bg-white dark:bg-neutral-800 min-w-[700px] px-4 rounded cursor-pointer border-b dark:border-b-neutral-700"
                            key={e.id}
                        >
                            <div className="flex items-center gap-4">
                                <p className="w-8">{e.priority}</p>
                                <div className="w-[150px] object-none aspect-video overflow-hidden flex">
                                    <img
                                        src={e.image_url}
                                        alt={e.name}
                                        className="w-full object-cover object-center"
                                    />
                                </div>
                                <div>
                                    {e.active ? (
                                        <span className="badge badge-success">Active</span>
                                    ) : (
                                        <span className="badge badge-danger">Inactive</span>
                                    )}
                                    <h3 className="text-g font-semibold">{e.name}</h3>
                                    {e.run_from ? <h4>{String(e.run_from).substr(0, 5)} - {String(e.run_to).substr(0, 5)}</h4> : <h4>All day</h4>}
                                </div>
                            </div>
                            <div className="flex gap-4 items-center">
                                <button
                                    onClick={() => {
                                        editModalHandler(e.id);
                                    }}
                                >
                                    <EditIcon />
                                </button>
                                <button
                                    onClick={() => {
                                        confirmDelete(e.id);
                                    }}
                                >
                                    <GarbageIcon />
                                </button>
                            </div>
                        </li>
                    );
                })}
            </ul>
            {showModal && (
                <EditModal
                    isLoading={isLoading}
                    title="Edit Poster"
                    closeModal={() => setShowModal(false)}
                    submitHandler={submitHandler}
                >
                    <div className="form-control w-full -mt-2">
                        <label className="label">
                            <span className="label-text dark:text-white">Select image</span>
                        </label>
                        <input
                            type="file"
                            id="editImage"
                            className="file-input w-full bg-neutral-100 dark:bg-neutral-700 dark:file-input-bordered"
                        />
                    </div>
                    <div>
                        <label htmlFor="edit-name" className="label-text dark:text-white">
                            Name
                        </label>
                        <input
                            value={editName}
                            onChange={(e) => {
                                setEditName(e.target.value);
                            }}
                            className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
                            id="edit-name"
                        />
                    </div>
                    <div>
                        <label htmlFor="editRunFrom" className="label-text dark:text-white">
                            Start
                        </label>
                        <Select
                            id="editRunFrom"
                            value={editRunFrom}
                            onChange={(e: any) => setEditRunFrom(e.target.value)}
                            className="w-full dark:bg-neutral-700 px-4 py-2.5 rounded-lg"
                        >
                            <option value="0">None</option>
                            {timeOptions.map((e: string) => (
                                <option key={e} value={e}>
                                    {e}
                                </option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        <label htmlFor="editRunTo" className="label-text dark:text-white">
                            End
                        </label>
                        <Select
                            id="editRunTo"
                            value={editRunTo}
                            onChange={(e: any) => setEditRunTo(e.target.value)}
                            className="w-full dark:bg-neutral-600 px-4 py-2.5 rounded-lg"
                        >
                            <option value="0">None</option>
                            {timeOptions.map((e: string) => (
                                <option key={e} value={e}>
                                    {e}
                                </option>
                            ))}
                        </Select>

                    </div>

                    <div>
                        <label htmlFor="edit-name" className=" label-text dark:text-white">
                            Menu order
                        </label>
                        <input
                            value={editOrder}
                            onChange={(e) => {
                                setEditOrder(e.target.value);
                            }}
                            className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
                            id="edit-order"
                        />
                    </div>
                    <div className="form-control w-max">
                        <label className="label cursor-pointer">
                            <input
                                type="checkbox"
                                checked={editActive}
                                onChange={() => {
                                    setEditActive((e) => !e);
                                }}
                                className="checkbox checkbox-success checkbox-sm mr-2"
                            />
                            <span className="label-text dark:text-white">Set Active</span>
                        </label>
                    </div>
                </EditModal>
            )}
            {addModal && (
                <AddModal
                    isLoading={isLoading}
                    title="Add Poster"
                    closeModal={() => setAddModal(false)}
                    submitHandler={addSubmitHandler}
                >
                    <div className="form-control w-full -mt-2">
                        <label className="label">
                            <span className="label-text dark:text-white">Select image</span>
                        </label>
                        <input
                            type="file"
                            id="image"
                            className="file-input w-full bg-neutral-100 dark:bg-neutral-700 dark:file-input-bordered"
                        />
                    </div>
                    <div>
                        <label htmlFor="editName" className="label-text dark:text-white">
                            Name
                        </label>
                        <input
                            className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
                            id="editName"
                        />
                    </div>
                    <div>
                        <label htmlFor="addRunFrom" className="label-text dark:text-white">
                            Start
                        </label>
                        <Select
                            id="addRunFrom"
                            value={addRunFrom}
                            onChange={(e: any) => setAddRunFrom(e.target.value)}
                            className="w-full dark:bg-neutral-900 px-4 py-2.5 rounded-lg"
                        >
                            <option value="0">None</option>
                            {timeOptions.map((e: string) => (
                                <option key={e} value={e}>
                                    {e}
                                </option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        <label htmlFor="addRunTo" className="label-text dark:text-white">
                            End
                        </label>
                        <Select
                            id="addRunTo"
                            value={addRunTo}
                            onChange={(e: any) => setAddRunTo(e.target.value)}
                            className="w-full dark:bg-neutral-900 px-4 py-2.5 rounded-lg"
                        >
                            <option value="0">None</option>
                            {timeOptions.map((e: string) => (
                                <option key={e} value={e}>
                                    {e}
                                </option>
                            ))}
                        </Select>
                    </div>

                    <div>
                        <label htmlFor="editOrder" className=" label-text dark:text-white">
                            Order
                        </label>
                        <input
                            value={editOrder}
                            onChange={(e) => {
                                setEditOrder(e.target.value);
                            }}
                            className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
                            id="editOrder"
                        />
                    </div>
                    <div className="form-control w-max">
                        <label className="label cursor-pointer">
                            <input
                                type="checkbox"
                                checked={editActive}
                                onChange={() => {
                                    setEditActive((e) => !e);
                                }}
                                className="checkbox checkbox-success checkbox-sm mr-2"
                                id="editActive"
                            />
                            <span className="label-text dark:text-white">Set Active</span>
                        </label>
                    </div>
                </AddModal>
            )}
        </>
    );
};

export default Posters;

