import { format } from "date-fns";
import api from "../utilities/fetching";
import { OrderDTO, Product } from "../utilities/types";

/**
 * @param id
 * @returns Promise - category with products
 */
export const getCategory = async (id: number) => {
  return await api.get(`categories/${id}/products`);
};

/**
 * Remove product
 * @param id
 * @returns promise - api response
 */
export const removeProduct = async (id: number) => {
  return await api.delete(`products/${id}`);
};

/**
 * Edit existing product by ID
 * @param id
 * @param payload (name, image, menu_order, active, category)
 * @returns promise - api response
 */

export const editProduct = async (id: number, payload: Product) => {
  return await api.postImage(`products/${id}?_method=PUT`, payload);
};

/**
 * Add a new product
 * @param payload (name, image, menu_order, active)
 * @returns promise - api response
 */
export const addProduct = async (payload: Product) => {
  return await api.postImage(`products`, payload);
};

export const getRecentProducts = async () => {
  return await api.get(`products/recent`);
};

export const getTables = async () => {
  return await api.get("orders/tables");
};

export const addTable = async (payload: { amount: number; name?: string }) => {
  return await api.post("orders/tables", payload);
};

export const clearTable = async (id: number) => {
  return await api.delete(`orders/tables/${id}/clear`);
};

export const getTableOrders = async (id: number) => {
  return await api.get(`tables/${id}/orders`);
};

export const createOrder = async (id: number, product_id: number) => {
  return await api.post(`tables/${id}/orders`, { product_id: product_id });
};

export const incrementQuantity = async (id: number) => {
  return await api.patch(`orders/${id}/increment`);
};

export const decrementQuantity = async (id: number) => {
  return await api.patch(`orders/${id}/decrement`);
};

export const getActiveOrders = async () => {
  return await api.get(`orders/active`);
};

export const getHistoryOrders = async (
  page: number,
  filters: {
    table: string | number;
    startDate: Date;
    endDate: Date;
    sortBy: string;
  },
) => {
  let endUrl = `orders?page=${page}&perPage=10`;
  const formattedStartDate =
    format(filters.startDate, "yyyy-MM-dd'T'HH:mm:ss") ||
    "2023-06-01T00:00:00.000Z";
  const formattedEndDate =
    format(filters.endDate, "yyyy-MM-dd'T'HH:mm:ss") ||
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");

  endUrl += filters.table ? `&table=${filters.table || "0"}` : "";
  endUrl += `&start=${formattedStartDate}`;
  endUrl += `&end=${formattedEndDate}`;
  endUrl += `&sortBy=${filters.sortBy}`;

  const response = await api.get(`${endUrl}`);
  return await response;
};

export const getOrderDetails = async (id: number) => {
  return await api.get(`orders/${id}/details`);
};

export const getPosters = async () => {
  return await api.get(`posters/featured`);
};

/**
 * Remove product
 * @param id
 * @returns promise - api response
 */
export const removePoster = async (id: number) => {
  return await api.delete(`posters/${id}/featured`);
};

export interface Poster {
  id: number;
  name: string;
  image_url: string;
  priority: number;
  active: boolean;
  run_from: string;
  run_to: string;
  created_at: string;
  updated_at: string;
}

/**
 * Edit existing product by ID
 * @param id
 * @param payload (name, image, menu_order, active, category)
 * @returns promise - api response
 */

export const editPoster = async (id: number, payload: Poster) => {
  return await api.postImage(`posters/${id}/featured?_method=PUT`, payload);
};

/**
 * Add a new product
 * @param payload (name, image, menu_order, active)
 * @returns promise - api response
 */
export const addPoster = async (payload: Poster) => {
  return await api.postImage(`posters/featured`, payload);
};
